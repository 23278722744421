@import '../../../../scss/theme-bootstrap';

.product-quickshop-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
}

.product-quickshop {
  position: relative;
  line-height: 1;
  z-index: 1;
  background: $color-white;
  @include breakpoint($medium-up) {
    padding: 0;
    &:after {
      content: '';
      position: absolute;
      top: -10px;
      #{$rdirection}: -10px;
      bottom: -10px;
      #{$ldirection}: -10px;
      z-index: -1;
      border: 10px solid $color-white;
      box-shadow: 0 0 0 1px $color-border;
    }
    .qs-overlay-cbox & {
      border: 0;
      padding: 10px;
      &:after {
        display: none;
      }
    }
    .product {
      &__subline {
        margin-top: 5px;
      }
    }
  }
  @include breakpoint($landscape-up) {
    &.js-quickshop-overlay {
      img {
        width: 100%;
      }
    }
    &__carousel__thumbnail {
      display: flex;
      margin-#{$rdirection}: 15px;
      cursor: pointer;
      width: 60px;
      align-items: center;
    }
  }
}

.product-quickshop__container {
  .product-grid--theme-black & {
    color: $color-white;
    a,
    a.selectBox,
    select {
      color: $color-white;
    }
    .sku-list__button,
    .selectBox-label {
      color: $color-black;
    }
  }

  @include breakpoint($medium-up) {
    @include pie-clearfix;
    background: $color-mpp-light-gray;
    .product-quickshop-wrapper & {
      .product-grid--theme-white & {
        background-color: $color-white;
      }
      .product-grid--theme-light-sand & {
        background-color: $color-light-sand;
      }
      .product-grid--theme-sand & {
        background-color: $color-sand;
      }
      .product-grid--theme-light-green & {
        background-color: $color-light-green;
      }
      .product-grid--theme-light-blue & {
        background-color: $color-light-blue;
      }
      .product-grid--theme-black & {
        @include font-smoothing(true);
        background-color: $color-black;
      }
    }
  }
}

.product-quickshop__media {
  position: relative;
  @include breakpoint($medium-up) {
    float: $ldirection;
    width: 46.72897%;
    margin-#{$rdirection}: 5.14019%;
    overflow: hidden;
  }
}

.product-quickshop__content {
  padding: 0 20px 20px;
  @include breakpoint($medium-up) {
    float: $ldirection;
    width: 42.99065%;
    margin-#{$rdirection}: 5.14019%;
    max-width: 410px;
    padding: 35px 0;
  }
}

.product-quickshop__image {
  text-align: center;
  position: relative;
}

.product-quickshop__header {
  text-align: center;
  padding: 0 5% 15px;
  margin: 0 0 15px;
  border-bottom: 1px solid $color-border;
  @include breakpoint($medium-up) {
    text-align: $ldirection;
    padding: 0;
    margin: 0;
    border: 0;
  }
}

.product-quickshop__price-wrapper {
  @include breakpoint($medium-up) {
    @include pie-clearfix;
    border-top: 1px solid $color-extra-light-gray;
    border-bottom: 1px solid $color-extra-light-gray;
    margin: 15px 0;
    display: flex;
  }
}

.product-quickshop__price {
  @include breakpoint($medium-up) {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    padding: 10px 0;
    width: 100%;
  }
}

.product-quickshop__rating {
  @include breakpoint($medium-up) {
    display: flex;
    align-self: center;
    min-width: 30%;
    justify-content: flex-end;
  }
}

.product-quickshop__summary {
  font-size: $base-mobile-font-size;
  line-height: $base-mobile-line-height;
  margin: 10px 0;
  .product-grid--theme-black & {
    border-bottom: 0px;
    a {
      border-color: $color-neutral-gray;
    }
  }
  @include breakpoint($medium-up) {
    font-size: $base-font-size;
    line-height: $base-line-height;
    border-bottom: 1px solid $color-extra-light-gray;
    margin: 15px 0;
    padding-bottom: 20px;
    .product-grid--theme-black & {
      border-bottom: 1px solid $color-extra-light-gray;
    }
  }
  p {
    margin: 0 0 10px;
  }
  &-link {
    @include link--secondary;
  }
}

.product-quickshop__sku {
  position: relative;
  .product-inventory-status {
    position: absolute;
    top: 0;
    #{$rdirection}: 20px;
    margin: 0;
    @include breakpoint($medium-up) {
      #{$rdirection}: 0;
    }
  }
}

.product-quickshop__cta {
  text-align: center;
  margin: 0 0 10px;
  .button {
    width: 100%;
  }
  @include breakpoint($medium-up) {
    text-align: $ldirection;
    margin-#{$rdirection}: 4px;
    margin-bottom: 5px;
    display: inline-block;
    .button {
      width: auto;
    }
  }
}

.product-quickshop__social {
  display: none;
  @include breakpoint($medium-up) {
    display: block;
    position: absolute;
    bottom: 35px;
    #{$rdirection}: 10px;
  }
}

.product-quickshop__offer {
  font-size: 14px;
  margin: 12px 0 15px;
  a {
    color: $color-text;
    &:hover {
      text-decoration: none;
    }
  }
}

.product-quickshop__close {
  position: absolute;
  top: 7px;
  #{$rdirection}: 7px;
  font-size: 14px;
  color: $color-black;
  text-decoration: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
  @include breakpoint($medium-up) {
    font-size: 18px;
  }
  .qs-overlay-cbox & {
    display: none;
  }
}

.product-quickshop__image {
  margin: 0 auto;
  text-align: center;
  position: relative;
  @include breakpoint($landscape-up) {
    max-width: 100%;
  }
  &-carousel {
    max-width: 100%;
    margin: 0 auto;
  }
  &-thumbnails {
    display: none;
    @include breakpoint($landscape-up) {
      position: relative;
      left: 0;
      top: 0;
      transform: none;
      display: flex;
    }
  }
  &-img {
    @include breakpoint($landscape-up) {
      padding-#{$ldirection}: 1px;
    }
  }
}
